<template>
	<div class="content-wrap inventory">
		<Spin fix v-if="loading"></Spin>
		<!--搜索框-->
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="药品编号">
				<Input placeholder="请输入药品编号" v-model="searchParams.drugNO" />
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="状态">
				<Select
					v-model="searchParams.status"
					:transfer="true"
					placeholder="请选择状态"
				>
					<Option :value="0">未发放</Option>
					<Option :value="1">已发放</Option>
					<Option :value="2">已回收</Option>
				</Select>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>

		<!--操作按钮-->
		<CtmsAction>
			<Button @click="() => this.$router.go(-1)">返回</Button>
			<Button
				v-if="
					projectActionPermissions.indexOf('btn_project_goods_drug_add') > -1
				"
				v-show="!isPharmacy"
				@click="handleAddShow"
				>新增批次</Button
			>
			<!--<Button v-if="!isPharmacy" @click="handleAddShow">批量导入</Button>-->
		</CtmsAction>

		<Row class="drug-info">
			<Col span="6">
				<span class="label">仓库编号：</span>
				{{ drugInfo.pharmacyNo }}
			</Col>
			<Col span="6">
				<span class="label">仓库名称：</span>
				{{ drugInfo.pharmacyName }}
			</Col>
			<Col span="6">
				<span class="label">温度警戒区间：</span>
				{{ drugInfo.tempMin ? drugInfo.tempMin + "℃" : "" }}
				~
				{{ drugInfo.tempMax ? drugInfo.tempMax + "℃" : "" }}
			</Col>
			<Col span="6">
				<span class="label">湿度警戒区间：</span>
				{{ drugInfo.humMin ? drugInfo.humMin + "%" : "" }}
				~
				{{ drugInfo.humMax ? drugInfo.humMax + "%" : "" }}
			</Col>
			<Col span="6">
				<span class="label">药品名称：</span>
				{{ drugInfo.drugName }}
			</Col>
			<Col span="6">
				<span class="label">给药途径：</span>
				{{ drugInfo.drugRouteName }}
			</Col>
			<Col span="6">
				<span class="label">临床分类：</span>
				{{ drugInfo.drugTypeName }}
			</Col>
		</Row>

		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		></CtmsDataGrid>

		<!--新增批次侧边栏-->
		<Add
			:visible="addVisible"
			:drugInfo="drugInfo"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
		></Add>

		<!--修改记录侧边栏-->
		<Update
			:visible="updateVisible"
			@onCancel="updateVisible = false"
			@onOk="handleUpdateOk"
			:updateId="updateId"
		></Update>
	</div>
</template>

<script>
import api from "@/api/project/inventory/medicine"

import { mapState } from "vuex"
import Update from "./Update.vue"
import Add from "./Add.vue"

const { apiGetPage, apiGetDrug, apiCallBack, apiDispense } = api

export default {
	name: "pharmacyList",
	components: {
		Update,
		Add
	},
	data() {
		const columns = [
			{ title: "药品编号", key: "drugNo", minWidth: 150 },
			{ title: "药品数量", key: "amount", minWidth: 100 },
			{ title: "批号", key: "batchNo", minWidth: 100 },
			{ title: "有效期", key: "validityDate", minWidth: 120 },
			{ title: "创建时间", key: "createdTime", minWidth: 180 },
			{
				title: "状态",
				key: "status",
				minWidth: 100,
				render: (h, params) => {
					let txt = "未发放"
					let colors = "#515a6e"
					if (params.row.status) {
						txt = params.row.status === 2 ? "已回收" : "已发放"
						colors = params.row.status === 2 ? "#ed4014" : "#2db7f5"
					}
					return h(
						"span",
						{
							style: {
								color: colors
							}
						},
						txt
					)
				}
			},
			{
				title: "操作",
				key: "action",
				width: 200,
				fixed: "right",
				renderButton: params => {
					const actionList = [
						{
							label: "修改",
							on: {
								click: this.handleUpdateShow
							}
						},
						{
							label: "回收",
							on: {
								click: this.handleCallback
							},
							confirm: {
								title: "您确认回收吗？"
							}
						},
						{
							label: "发放",
							on: {
								click: this.handleDispense
							},
							confirm: {
								title: "您确认发放吗？"
							}
						}
					]
					// 已发放
					if (params.row.status === 1) {
						actionList[2] = null
					}
					// 已回收
					if (params.row.status === 2) {
						actionList[0] = null
						actionList[1] = null
						actionList[2] = null
					}
					// 权限控制
					if (
						this.projectActionPermissions.indexOf(
							"btn_project_goods_drug_add"
						) === -1
					) {
						actionList[0] = null
						actionList[1] = null
						actionList[2] = null
					}
					return actionList.filter(item => !!item)
				}
			}
		]
		if (this.$route.query.type === "pharmacy") {
			columns.splice(6, 1)
		}
		return {
			listData: [],
			columns,
			loading: false,
			addVisible: false, // 新增批次
			updateVisible: false, // 修改记录
			updateId: "",
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			searchParams: {
				status: "",
				drugNO: ""
			},
			drugInfo: {}
		}
	},
	created() {
		this.getDrugDetail()
		this.initList()
	},
	computed: {
		...mapState({
			dataGridHeight(state) {
				if (this.isPharmacy) {
					return state.contentHeight - 190
				}
				return state.contentHeight - 210 - state.project.infoHeight
			}
		}),
		...mapState("permission", ["projectActionPermissions"]),
		isPharmacy() {
			return this.$route.query.type === "pharmacy"
		},
		projectId() {
			return this.$route.params.projectId || this.$route.query.projectId
		}
	},
	methods: {
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage({
					pageNum: this.page.current,
					pageSize: this.page.pageSize,
					projectId: this.projectId,
					drugTypeId: this.$route.query.drugId,
					...this.oldSearchParams
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		getDrugDetail() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetDrug({
					projectId: this.projectId,
					id: this.$route.query.drugId
				})
				if (res) {
					this.drugInfo = res.data
				}
				this.loading = false
			})
		},
		// 发放药物
		handleDispense({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiDispense({
					projectId: this.projectId,
					drugTypeId: this.$route.query.drugId,
					drugId: this.$route.query.drugId,
					ids: [row.id]
				})
				if (res) {
					this.$Message.success("发放成功！")
					this.initList()
				}
			})
		},
		// 回收药物
		handleCallback({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiCallBack({
					projectId: this.projectId,
					drugTypeId: this.$route.query.drugId,
					drugId: this.$route.query.drugId,
					ids: [row.id]
				})
				if (res) {
					this.$Message.success("回收成功！")
					this.initList()
				}
			})
		},
		// 新增显示
		handleAddShow() {
			this.addVisible = true
		},
		// 新增成功刷新列表
		handleAddOk() {
			this.addVisible = false
			this.$nextTick(() => {
				this.initList()
			})
		},
		handleUpdateOk() {
			this.updateVisible = false
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 修改显示
		handleUpdateShow({ row }) {
			this.updateVisible = true
			this.updateId = row.id
		},

		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				status: "",
				drugNO: ""
			}
			this.searchHandle()
		},
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		}
	}
}
</script>
<style lang="less">
.inventory {
	.drug-info {
		line-height: 30px;
		color: #202730;
		.ivu-col-span-6 {
			padding-left: 15px;
		}
		.label {
			color: #999;
		}
	}
}
</style>
